<template>
	<div>
		<h1>Privacy Policy</h1>

		<p>
			Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
			doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
			inventore verita tis et quasi architecto beatae vitae dicta sunt
			explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
			odit aut fugit, sed quia consequuntur ma gni dolores eos qui ratione
			voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum
			quia dolor sit amet, consectetur, adipisci velit, sed quia noni Sed ut
			perspiciatis unde omnis iste natus error sit voluptatem accusantium
			doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
			inventore verita tis et quasi architecto beatae vitae dicta sunt
			explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
			odit aut fugit, sed quia consequuntur ma gni dolores eos qui ratione
			voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum
			quia dolor sit amet, consectetur, adipisci velit, sed quia noni Sed ut
			perspiciatis unde omnis iste natus error sit voluptatem accusantium
			doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
			inventore verita tis et quasi architecto beatae vitae dicta sunt
			explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
			odit aut fugit, sed quia consequuntur ma gni dolores eos qui ratione
			voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum
			quia dolor sit amet, consectetur, adipisci velit, sed quia noni Sed ut
			perspiciatis unde omnis iste natus error sit voluptatem accusantium
			doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
			inventore verita ae consequatur, vel illum qui dolorem eum fugiat quo
			voluptas nulla pariatur
		</p>

		<ul>
			<li>
				Sed ut perspiciatis unde omnis iste natus error sit voluptatem
				accusantium doloremque laudantium, totam rem aperiam, eaque
			</li>
			<li>Sed ut perspiciatis unde omnis iste natus error sit voluptatem</li>
			<li>
				Sed ut perspiciatis unde omnis iste natus error sit voluptatem
				doloremque laudantium, totam rem aperiam, eaque
			</li>
		</ul>
		<p>
			Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
			doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
			inventore verita tis et quasi architecto beatae vitae dicta sunt
			explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
			odit aut fugit, sed quia consequuntur ma gni dolores eos qui ratione
			voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum
			quia dolor sit amet, consectetur, adipisci velit, sed quia noni Sed ut
			perspiciatis unde omnis iste natus error sit voluptatem accusantium
			doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
			inventore verita tis et quasi architecto beatae vitae dicta sunt
			explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
			odit aut fugit, sed quia consequuntur ma gni dolores eos qui ratione
			voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum
			quia dolor sit amet, consectetur, adipisci velit, sed quia noni Sed ut
			perspiciatis unde omnis iste natus error sit voluptatem accusantium
			doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
			inventore verita tis et quasi architecto beatae vitae dicta sunt
			explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
			odit aut fugit, sed quia consequuntur ma gni dolores eos qui ratione
			voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum
			quia dolor sit amet, consectetur, adipisci velit, sed quia noni Sed ut
			perspiciatis unde omnis iste natus error sit voluptatem accusantium
			doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
			inventore verita tis et quasi architecto beatae vitae dicta sunt
			explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
			odit aut fugit, sed quia consequuntur ma gni dolores eos qui ratione
			voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum
			quia dolor sit amet, consectetur, adipisci velit, sed quia noni Sed ut
			perspiciatis unde omnis iste natus error sit voluptatem accusantium
			doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
			inventore verita Sed ut perspiciatis unde omnis iste natus error sit
			voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque
			ipsa quae ab illo inventore verita gni dolores eos qui ratione voluptatem
			sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit
			amet, consectetur, adipisci velit, sed quia non ae consequatur, vel illum
			qui dolorem eum fugiat quo voluptas nulla pariatur
		</p>
	</div>
</template>

<script>
export default {
	name: 'PrivacyPolicy'
}
</script>

<style>
</style>
